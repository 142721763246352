.custom-snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 1000;
  }
  
  .custom-snackbar.show {
    opacity: 1;
  }
  
  .custom-snackbar.hide {
    display: none;
  }
  
  .message {
    display: inline-block;
  }
  
  .close-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    float: right;
    font-size: 18px;
    line-height: 1;
  }
  