/* CSS for the modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modal-height {
    display: flex;
    position: absolute;
    top: 20%;    
    justify-content: center;
    align-items: center;
    // height: 70vh;
    width: 100%;
  }
  
  .disclaimer {
    
    margin-top: 10px;
    color: #888; /* Adjust the color as needed */
    font-size: 14px;
  }
  
  
  .modal-content {
    width: 80%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Style for the close button */
  .modal-content button {
    background: #f44336;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .modal-content button:hover {
    background: #d32f2f;
  }

  .modal-button {
  background: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.modal-button:hover {
  background: #0056b3;
}

